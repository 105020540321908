<template>
    <v-card>
        <v-card-title
            class="indigo white--text"
        >
            <h3>Cadastro de Clientes</h3>
            <v-spacer></v-spacer>
            Gestor Food
        </v-card-title>
        <v-stepper v-model="e1">
            <v-stepper-header>
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                >
                    <h3>Dados do cliente</h3>
                </v-stepper-step>

                <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                >
                    Enderço
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card
                        class="mb-12"
                    >
                        <v-card-text class="pt-4" >
                            <v-form
                                ref="userForm"
                            >
                                <v-alert
                                    v-model="alert.show"
                                    dismissible
                                    color="light-blue dark-1"
                                    border="left"
                                    elevation="2"
                                    colored-border
                                    transition="scale-transition"
                                >
                                    {{alert.text}}
                                </v-alert>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="8"
                                    >
                                    <v-text-field
                                        label="Nome"
                                        maxlength="20"
                                        counter
                                        type="text"
                                        v-model="form.nome"
                                        :rules="[
                                            form_nome_rules.required, form_nome_rules.counter,
                                                form_nome_rules.min
                                        ]"
                                    ></v-text-field>
                                    </v-col>
                                    
                                    <v-col
                                        cols="12"
                                        md="4"
                                    >
                                    <v-text-field
                                        label="CPF"
                                        maxlength="14"
                                        v-model="form.cpf"
                                        v-mask="'###.###.###-##'"
                                        :rules="[
                                            form_cpf_rules.required, form_cpf_rules.counter,
                                                form_cpf_rules.min
                                        ]"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                    <v-text-field
                                        label="Telefone"
                                        maxlength="15"
                                        v-model="form.telefone"
                                        v-mask="maskTel()"
                                        :rules="[
                                            form_telefone_rules.required, form_telefone_rules.counter,
                                                form_telefone_rules.min
                                        ]"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                    <v-text-field
                                        label="Senha"
                                        maxlength="15"
                                        hint="Pelo menos 8 caracteres"
                                        v-model="form.senha"
                                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show1 ? 'text' : 'password'"
                                        @click:append="show1 = !show1"
                                        :rules="[
                                            form_login_rules.required, form_login_rules.min
                                        ]"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>

                    <v-btn
                        color="primary"
                        @click="createUser"
                    >
                        Continue
                    </v-btn>

                    <v-btn text @click.prevent="clearForm()">
                        Limpar
                    </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card
                    class="mb-12"
                    >
                        <v-card-text class="pt-4" >
                            <v-form
                                ref="addressForm"
                            >

                                <v-alert
                                    v-model="alert.show"
                                    dismissible
                                    color="light-blue dark-1"
                                    border="left"
                                    elevation="2"
                                    colored-border
                                    transition="scale-transition"
                                >
                                    {{alert.text}}
                                </v-alert>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="2"
                                    >
                                    <v-text-field
                                        label="CEP"
                                        maxlength="9"
                                        v-model="form.cep"
                                        v-mask="'#####-###'"
                                        @keyup="onKeyup"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="8"
                                    >
                                    <v-text-field
                                        label="Rua"
                                        maxlength="128"
                                        type="text"
                                        v-model="form.rua"
                                        :rules="[
                                            form_rua_rules.required, form_rua_rules.counter
                                        ]"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="2"
                                    >
                                    <v-text-field
                                        label="Numero"
                                        maxlength="5"
                                        v-model="form.numero"
                                        :rules="[
                                            form_numero_rules.required, form_numero_rules.counter
                                        ]"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                    <v-text-field
                                        label="Bairro"
                                        maxlength="64"
                                        type="text"
                                        v-model="form.bairro"
                                        :rules="[
                                            form_bairro_rules.required, form_bairro_rules.counter
                                        ]"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="3"
                                    >
                                    <v-text-field
                                        label="Cidade"
                                        maxlength="64"
                                        type="text"
                                        v-model="form.cidade"
                                        :rules="[
                                            form_cidade_rules.required, form_cidade_rules.counter
                                        ]"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="2"
                                    >
                                    <v-text-field
                                        label="UF"
                                        maxlength="2"
                                        type="text"
                                        v-model="form.uf"
                                        :rules="[
                                            form_uf_rules.required, form_uf_rules.counter, form_uf_rules.min
                                        ]"
                                    ></v-text-field>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        md="4"
                                    >
                                    <v-text-field
                                        label="Complemento"
                                        maxlength="20"
                                        type="text"
                                        v-model="form.complemento"
                                    ></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col
                                        cols="12"
                                        md="6"
                                    >
                                    <v-textarea
                                        label="Referencia"
                                        maxlength="124"
                                        type="text"
                                        outlined
                                        counter
                                        rows="1"
                                        row-height="15"
                                        v-model="form.referencia"
                                    ></v-textarea>
                                    </v-col>
                                    
                                    <v-checkbox
                                        color="indigo"
                                        true-value="T"
                                        false-value="F"
                                        v-model="form.principal"
                                        :label="`Endereço Principal?`"
                                    ></v-checkbox>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col
                            cols="12"
                            md="2"
                        >
                            <v-btn
                                color="primary"
                                @click="createAddress"
                            >
                                Salvar
                            </v-btn>
                        </v-col>

                        <v-col
                            cols="12"
                            md="2"
                        >
                            <v-btn
                                color="primary"
                                @click="e1 = 1"
                            >
                                Voltar
                            </v-btn>
                        </v-col>

                        <v-col
                            cols="12"
                            md="2"
                        >
                            <v-btn text @click.prevent="clearForm()">
                                Limpar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-overlay :value="overlay">
			<v-progress-circular
				indeterminate
				size="64"
			></v-progress-circular>
		</v-overlay>
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    data () {
        return {
            overlay: false,
            user_id: 0,
            e1: 1,
            show1: false,
            form: {
                nome: '',
                cpf: '',
                telefone: '',
                senha: '',
                cep: '',
                rua: '',
                numero: '',
                bairro: '',
                cidade: '',
                uf: '',
                complemento: '',
                referencia: '',
                principal: 'F'
            },
            alert: {
                show: false,
                text: "",
            },



            form_nome_rules: {
                required: value => !!value || 'Nome é obrigatório.',
                counter: value => value.length <= 20 || 'Nome inválido!',
                min: v => v.length >= 2 || 'Mínimo de 2 caracteres'
            },
            form_cpf_rules: {
                required: value => !!value || 'CPF é obrigatório.',
                counter: value => value.length <= 14 || 'CPF inválido!',
                min: v => v.length >= 14 || 'CPF inválido!'
            },
            form_telefone_rules: {
                required: value => !!value || 'Telefone é obrigatório.',
                counter: value => value.length <= 15 || 'Telefone inválido!',
                min: v => v.length >= 14 || 'Telefone inválido'
            },
            form_login_rules: {
                required: value => !!value || 'Senha é obrigatória.',
                min: v => v.length >= 8 || 'Mínimo de 8 caracteres'
            },
            form_rua_rules: {
                required: value => !!value || 'Rua é obrigatória.',
                counter: value => value.length <= 128 || 'Rua inválida!'
            },
            form_numero_rules: {
                required: value => !!value || 'Número é obrigatório.',
                counter: value => value.length <= 5 || 'Numero inválido!'
            },
            form_bairro_rules: {
                required: value => !!value || 'Bairro é obrigatório.',
                counter: value => value.length <= 64 || 'Bairro inválido!'
            },
            form_cidade_rules: {
                required: value => !!value || 'Cidade é obrigatória.',
                counter: value => value.length <= 64 || 'Cidade inválida!'
            },
            form_uf_rules: {
                required: value => !!value || 'Estado é obrigatório.',
                counter: value => value.length <= 2 || 'Estado inválido!',
                min: v => v.length >= 2 || 'Estado inválido'
            },
        }
    },

    methods: {
        ...mapMutations({
            doLogin: 'LOGIN'
        }),

        ...mapActions({
            saveClient  : 'NOVO_CLIENTE',
            saveAddress : 'NOVO_ENDERECO'
        }),

        maskTel() {
            if(this.form.telefone == '') {
                return ''
            } else {
                return this.form.telefone.length == 15 ? '(##) #####-####' : '(##) ####-####'
            }
        },

        onKeyup: function() {
            if ( this.form.cep.length == 9 ) {
            this.overlay = true
			fetch('https://viacep.com.br/ws/'+this.form.cep+'/json/', {method: 'get'})
                .then(response => (response.json()))
                .then(responseJson => {
                    this.form.rua = responseJson.logradouro
                    this.form.bairro = responseJson.bairro
                    this.form.cidade = responseJson.localidade
                    this.form.uf = responseJson.uf
                })
                .catch(() => {
                    this.alert = {
                        show: true,
                        message: 'Problemas internos. Tente novamente.'
                    }
				})
				.finally(() => {
					this.overlay = false
				})
            }
        },

        clearForm: function() {
            for (let prop in this.form) {
                this.form[prop] = ''
            }
        },

        createUser: function(){
            if (this.$refs.userForm.validate()) {
                this.overlay = true
                this.saveClient({
                    nome: this.form.nome,
                    cpf: this.form.cpf,
                    telefone: this.form.telefone,
                    senha: this.form.senha
                })
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if(resJSON.data){
                            this.user_id = resJSON.response
                            this.e1 = 2                        
                        }else{
                            this.alert = {
                                show: true,
                                message: resJSON.message
                            }
                        }
                    })
                    .catch(() => {
                        this.alert = {
                            show: true,
                            message: 'Problemas internos. Tente novamente.'
                        }
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        },

        createAddress: function(){
            if (this.$refs.addressForm.validate()) {
                this.overlay = true
                this.saveAddress({
                    cep: this.form.cep,
                    rua: this.form.rua,
                    numero: this.form.numero,
                    bairro: this.form.bairro,
                    cidade: this.form.cidade,
                    uf: this.form.uf,
                    complemento: this.form.complemento,
                    referencia: this.form.referencia,
                    principal: this.form.principal,
                    user_id: this.user_id
                })
                    .then(res => (res.json()))
                    .then(resJSON => {
                        if(resJSON.data){
                            this.alert = {
                                show: true,
                                message: 'Usuário cadastrado com sucesso.'
                            }

                            // this.$ doLogin({
                            //     name: this.form.nome,
                            //     phone: this.form.telefone,
                            //     email: null
                            // }).then(() => {
                                this.$router.push({
                                    name: 'Login'
                                })
                            // })
                        }else{
                            this.alert = {
                                show: true,
                                message: resJSON.message
                            }
                        }
                    })
                    .catch(() => {
                        this.alert = {
                            show: true,
                            message: 'Problemas internos. Tente novamente.'
                        }
                    })
                    .finally(() => {
                        this.overlay = false
                    })
            }
        }
    }
}
</script>